// export const environment = {
//     production: false,
//     baseUrl: 'https://qa-backend-core-app.azurewebsites.net/api/',
//     /**
//      * login related configuration varibale's
//      */
//     externalTenant: 'B2C_1_BIS-External-Dev',
//     internalTenant: 'B2C_1_BIS-Internal-Dev',
//     passwordPolicyName: 'B2C_1_PasswordReset-Dev',
//     tenantName: 'devqab2c',
//     tenantId: '07a94a91-1560-4425-9096-b966244ec34c',
//     clientId: '29d6d5ee-8094-4959-8fa7-ad92963192e6',
//     enableRolesAndPreferences: false
// };
export const environment = {
  production: false,
  baseUrl: 'https://api.devbis1.epicbrokers.com/api/',
  paymentUrl:'https://api.epaypolicydemo.com:443/api/',
  apiKey : "a85a8a826b5849c",
  apiSecretKey : "4b9dae960adf43c",
  /**
   * login related configuration varibale's
   */
  externalTenant: 'B2C_1A_SUSIFPR',
  internalTenant: 'B2C_1_BIS-Internal',
  forgotPolicyTenantName: 'B2C_1_PasswordReset_UAT',
  changePwdPolicy:'B2C_1A_ProfileEditPasswordChange',
  tenantName: 'epicnonprodb2c',
  tenantId: 'b7fb1207-85ba-4f16-a239-45be399ef924',
  clientId: 'b8f589f9-3bed-490d-ae25-8adc95b6914a',
  enableRolesAndPreferences: true,
  homepage: 'bondmanagement',
  environmentType: "DEV",
    /**
     * Used to configure user idle time and timeouts
     */
    idleTime: 1200,
    timeout: 60
};
